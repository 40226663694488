#api-usage-bar-chart canvas {
    height: 425px !important;
}

#xr-col-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

#xr-logo-container .avatar-xl {
    text-align: center;
}

#xr-logo-container .img-thumbnail {
    border: 4px solid #7D71FF !important;
    padding: 0 !important;
}

#xr-col-container .payment-icon i {
    font-size: 40px;
}

#client-manage-payment-btn {
    border-radius: 100px;
}