input,
select {
    border-radius: 150px !important;
    border: none !important;
    background: #18194B !important;
    color: #fff !important;
}

input::placeholder {
    font-size: 10px !important;
}

/* input[type='checkbox'] {
    background: #fff !important;
    accent-color: #212252 !important;
}

.form-switch .form-check-input:checked {} */
input[type="checkbox"]:checked {
    border-color: greenyellow !important;
    background-color: greenyellow !important;
}

label {
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

button {
    border-radius: 150px !important;
}

.simplebar-content {
    height: 100%;
}

#sidebar-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#logout-btn-left-panel-container {
    margin-top: auto;
    margin-inline: auto;
    width: 85%;
}

.vertical-menu {
    background: #23234E !important;
}

.navbar-brand-box {
    background: #18194B !important;
}

#sign-in-container {
    background: #23234E !important;
}

#page-topbar {
    background: #18194B !important;
}

#model-header-mxr i {
    font-size: 24px;
    color: #33FAF7;
    margin-right: 6px;
}

#model-header-mxr .modal-title {
    color: #fff !important;
}

.modal-title {
    display: flex;
}

.modal-content {
    border-radius: 32px !important;
    border: 1px solid rgba(255, 255, 255, 0.43) !important;
    background: #212252 !important;
    color: #fff;
}

#payment-page-content {
    background-color: #fff;
}